import { useMutation } from "@tanstack/react-query"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { removeSlugCart } from "redux/features/cart"
import {
  handleApiSuccess,
  handleError,
  handlePostRequest,
} from "utils/api-handler.util"

interface IUserProceedToPayment {
  lockId: string
  slug: string
  isFree: boolean
}

export function useCheckOut({
  lockId,
  slug,
  isFree = false,
}: IUserProceedToPayment) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const mutation = useMutation({
    mutationFn: async () => {
      const response = await handlePostRequest("/checkout", {
        lock_identifier: lockId,
      })

      return response
    },
    onSuccess: (response) => {
      dispatch(removeSlugCart(slug))

      handleApiSuccess(response)

      if (isFree) {
        navigate("/my-tickets/?tab=Current")
      } else {
        const iPayUrl = response.data.data.submit_url
        const paymentData = response.data.data.data

        handlePayment(iPayUrl, paymentData)
      }
    },
    onError: (err: any) => {
      handleError(err)
    },
  })

  return mutation
}

function getIPayFormData(data: any) {
  const formData = new FormData()

  Object.entries(data).forEach(([key, value]: any[]) =>
    formData.append(key, value),
  )
  return formData
}

function redirectToIPay(iPayUrl: string, formData: FormData): void {
  // Create a hidden form
  const form = document.createElement("form")
  form.style.display = "none"
  form.method = "POST"
  form.action = iPayUrl

  // Append form data as hidden input fields
  for (const [key, value] of formData.entries()) {
    const input = document.createElement("input")
    const inputValue: any = value

    input.type = "hidden"
    input.name = key
    input.value = inputValue
    form.appendChild(input)
  }

  // Append the form to the document body
  document.body.appendChild(form)

  // Submit the form
  form.submit()
}

export function handlePayment(paymentGateWayURL: string, paymentData: any) {
  const formData = getIPayFormData(paymentData)
  redirectToIPay(paymentGateWayURL, formData)
}
