import PageNav from "components/nav/PageNav"
import TicketInformation from "./ticket-information"
// import PaymentMethod from "./payment-method"
// import PaymentInformation from "./payment-information"
import RtButton from "components/button/RtButton"
import { Zap } from "react-feather"
import SectionContainer from "components/layout/SectionContainer"
import Counter from "components/count/Counter"
import { useGetPreCheckoutData } from "hooks/api/useGetPreCheckoutData"
import Spinner from "components/loader/Spinner"
import { useNavigate, useParams } from "react-router-dom"
import { getLockRemainingSeconds } from "utils/get-lock-remaining-time.util"
import { useEffect, useState } from "react"
import BaseModal from "components/modal/BaseModal"
import ActionLoadingModal from "components/modal/ActionLoadingModal"
import ExistingCartModal from "components/modal/ExistingCartModal"
import EventCard from "components/event-card/EventCard"
import RtTitle from "components/title/RtTitle"
import { useCheckOut } from "hooks/api/useCheckOut"

function Checkout() {
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [timerReset, setTimeReset] = useState(false)
  const { slug, lockId, expiresAt } = useParams()
  const remTimeInSeconds = getLockRemainingSeconds(expiresAt!)

  const { data, isLoading, isError, refetch } = useGetPreCheckoutData(lockId)
  const handleNavigateHome = () => navigate("/")
  const handlePurchaseTickets = () => navigate(`/events/${slug}`)
  const handleOpenCartCodeModal = () => {
    setIsModalOpen(true)
  }
  const queryClient = useCheckOut({
    lockId: lockId!,
    slug: slug!,
    isFree: data?.data.ticket_info.is_free,
  })

  const handleCheckout = async () => {
    queryClient.mutate()
  }

  useEffect(() => {
    if (isError) setShowModal(isError)
  }, [isError])

  const navigateToViewEvent = () => {
    handleOpenCartCodeModal()
  }
  if (isLoading) return <Spinner />

  return (
    <div className="bg-rt-light-violet min-h-screen">
      {data && (
        <>
          <SectionContainer>
            <PageNav checkOutModal={handleOpenCartCodeModal}>Checkout</PageNav>
            <Counter
              timeInSeconds={remTimeInSeconds}
              cbWhenTimeExpires={refetch}
              setTimerReset={setTimeReset}
              timerReset={timerReset}
            />
          </SectionContainer>

          <SectionContainer>
            {/* <div className=""> */}
            <RtTitle overrideClass="w-full mb-2">Event Information</RtTitle>
            <EventCard
              preview
              event={data.data.event}
              overrideClass="inline-block sm:min-w-[450px]"
            />
            {/* </div> */}
          </SectionContainer>

          <SectionContainer>
            <div>
              <TicketInformation ticketInfo={data.data.ticket_info} />
            </div>
          </SectionContainer>

          {/* {!data.data.ticket_info.is_free ? (
            <>
              <PaymentMethod />
              <PaymentInformation />
            </>
          ) : null} */}
          <SectionContainer overrideClass="text-center flex justify-between gap-4">
            {/* Back Button */}
            <RtButton
              overrideClass="bg-red-500 text-white w-1/4 py-2"
              onClick={() => navigateToViewEvent()}
            >
              Back
            </RtButton>

            {/* Existing Button */}
            <RtButton
              icon={<Zap color="white" className="mr-2" />}
              overrideClass="bg-blue-500 text-white w-2/3 py-2"
              onClick={handleCheckout}
            >
              {data.data.ticket_info.is_free
                ? "Proceed To Free Checkout"
                : "Proceed to payment"}
            </RtButton>
          </SectionContainer>
        </>
      )}

      <ActionLoadingModal
        isOpen={queryClient.isPending}
        loadingText="Please wait as your checkout is being processed"
      />

      <BaseModal isOpen={showModal} onClose={() => {}}>
        <div className="text-center">
          <h2>
            Oops! your ticket expired, no need to worry you can secure the
            tickets again.
          </h2>
          <div className="flex items-center  justify-between">
            <RtButton overrideClass="mt-4" onClick={handlePurchaseTickets}>
              Buy Same Tickets
            </RtButton>

            <RtButton overrideClass="mt-4" onClick={handleNavigateHome}>
              View other Events
            </RtButton>
          </div>
        </div>
      </BaseModal>
      <ExistingCartModal
        isOpen={isModalOpen}
        slug={slug!}
        onClose={() => setIsModalOpen(false)}
        lock_identifier={lockId!}
        checkout={true}
      />
    </div>
  )
}

export default Checkout
