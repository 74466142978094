import RtButton from "components/button/RtButton"
import RTCheckBox from "components/input/RTCheckBox"
import RtInput from "components/input/RtInput"
import ContentContainer from "components/layout/ContentContainer"
import SectionContainer from "components/layout/SectionContainer"
import ActionLoadingModal from "components/modal/ActionLoadingModal"
import PageNav from "components/nav/PageNav"
import RtTitle from "components/title/RtTitle"
import { handlePayment } from "hooks/api/useCheckOut"
import useSellTickets from "hooks/api/useSellTickets"
import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

function SellTickets() {
  const { lockId, slug } = useParams()
  const navigate = useNavigate()

  const [formData, setFormData] = useState({
    buyer_name: "",
    buyer_phone_number: "",
    buyer_email: "",
    is_cash: false,
  })

  const isCashPayment = formData.is_cash

  const handleChange = (value: { [key: string]: any }) => {
    setFormData((prev) => ({ ...prev, ...value }))
  }

  const queryClient = useSellTickets(slug!)
  const onSellTickets = () => {
    queryClient.mutate(
      { ...formData, lock_identifier: lockId },
      {
        onSuccess: (response) => {
          if (isCashPayment) {
            navigate("/sales")
          } else {
            handlePayment(
              response.data.data.submit_url,
              response.data.data.data,
            )
          }
        },
      },
    )
  }

  return (
    <div>
      <SectionContainer>
        <PageNav>Sell Tickets</PageNav>

        <RtTitle>Customer Info</RtTitle>

        <ContentContainer overrideClass="grid grid-cols-1 gap-4 mt-4">
          <RtInput
            inputName="buyer_name"
            inputType="text"
            inputPlaceholder="John Doe"
            inputOverrideClass="bg-rt-white bg-opacity-100"
            label="Buyer Name"
            inputValue={formData?.buyer_name || ""}
            onInputChange={handleChange}
            isRequired
          />

          <RtInput
            inputName="buyer_phone_number"
            inputType="number"
            inputPlaceholder="0721246468"
            inputOverrideClass="bg-rt-white bg-opacity-100"
            label="Buyer Phone Number"
            inputValue={formData?.buyer_phone_number || ""}
            onInputChange={handleChange}
            isRequired
          />

          <RtInput
            inputName="buyer_email"
            inputType="email"
            inputPlaceholder="john@gmail.com"
            inputOverrideClass="bg-rt-white bg-opacity-100"
            label="Buyer Email"
            inputValue={formData?.buyer_email || ""}
            onInputChange={handleChange}
            isRequired
          />

          <RTCheckBox
            inputName="is_cash"
            label="Payment Method"
            onInputChange={handleChange}
            rightText="Cash Payment"
          />
        </ContentContainer>
      </SectionContainer>

      <SectionContainer>
        <RtButton
          overrideClass="w-full"
          onClick={onSellTickets}
          disabled={queryClient.isPending}
        >
          Sell tickets
        </RtButton>
      </SectionContainer>

      <ActionLoadingModal
        isOpen={queryClient.isPending}
        loadingText="Please wait as your checkout is being processed"
      />
    </div>
  )
}

export default SellTickets
