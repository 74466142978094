import { useState, useEffect } from "react"

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [isFading, setIsFading] = useState(false)

  useEffect(() => {
    let fadeTimeout: any

    const handleScroll = () => {
      if (window.scrollY > 300) {
        setIsVisible(true)
        setIsFading(false)
        clearTimeout(fadeTimeout)
        fadeTimeout = setTimeout(() => {
          setIsFading(true)
        }, 1500) // Fade out after 1.5 seconds of inactivity
      } else {
        setIsVisible(false)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
      clearTimeout(fadeTimeout)
    }
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <div>
      {isVisible && (
        <button
          id="scroll"
          onClick={scrollToTop}
          className={`z-10 fixed bottom-4 right-4 bg-purple-600 text-xl font-bold text-white px-2 py-4 rounded-full shadow-lg hover:bg-purple-800 transition duration-300 ${
            isFading ? "opacity-0" : "opacity-100"
          }`}
          style={{ transition: "opacity 0.2s ease-in-out" }}
        >
          ↑
        </button>
      )}
    </div>
  )
}

export default ScrollToTopButton
